import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import {
  Banner,
} from 'components';
import { BASE_URL } from 'config';

const showdown = require('showdown');

const converter = new showdown.Converter();

const AboutPaidToLearnMaoriPage = ({ data }) => (
  <Layout page="about/our-staff-get-paid-to-learn-maori">

    <Helmet
      title="Haka Tourism Group’s Māori Language Policy | Haka Tours"
      meta={[
        {
          name: 'description', content: `We believe that the Māori Language is an integral part of understanding Aotearoa/New Zealand’s culture,
          which is why Haka Tourism Group pay our staff to learn Te Reo.`,
        },
        { name: 'og:title', content: 'Haka Tourism Group’s Māori Language Policy | Haka Tours' },
        {
          name: 'og:description', content: `We believe that the Māori Language is an integral part of understanding Aotearoa/New Zealand’s culture,
          which is why Haka Tourism Group pay our staff to learn Te Reo.`,
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/our-staff-get-paid-to-learn-maori/` }]}
    />

    <Banner backgroundImage="https://static-hakatours.imgix.net/paid-to-learn-maori.jpg?auto=format">
      <h1 className="c-heading c-heading--h0">We Reward Staff for Learning Te Reo</h1>
    </Banner>
    <section className="l-section">
      <div className="l-container l-container--small">
        <div
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(data.allStaticPages.edges[0].node.content_markdown),
          }}
        />
      </div>
    </section>
  </Layout>
);

AboutPaidToLearnMaoriPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPaidToLearnMaoriPage;

export const query = graphql`
  query {
    allStaticPages(
      filter: {
        slug: { eq: "our-staff-get-paid-to-learn-maori" },
      }
    ) {
      edges {
        node {
          id
          api_id
          slug
          content_markdown
        }
      }
    }
  }
`;
